<template>
  <div ref="el" class="h-full rounded-lg bg-secondary p-2">
    <div class="space-y-2" :class="{ 'animate-pulse': !overlay }">
      <div class="flex items-center justify-center">
        <div class="relative aspect-video w-full rounded-lg bg-dark">
          <template v-if="overlay">
            <nuxt-img :src="primaryScreenshotSrc" />
            <video
              v-if="isHovered && animate"
              class="absolute inset-0 rounded-lg"
              :src="primaryVideoSrc"
              muted
              loop
              autoplay
              :poster="primaryScreenshotSrc"
            />
          </template>

          <IconUltra
            v-if="overlay && overlay.prime"
            class="absolute right-0 top-0 m-1 rounded-full bg-dark/70 p-1 shadow"
            :class="{
              'h-6 w-6': hideAdditionalImages,
              'h-7 w-7': !hideAdditionalImages,
            }"
          />
        </div>
      </div>
      <div v-if="!hideAdditionalImages" class="grid grid-cols-2 gap-2">
        <div class="aspect-video rounded-lg bg-dark">
          <nuxt-img
            v-if="secondaryScreenshotSrc"
            :key="secondaryScreenshotSrc"
            :src="secondaryScreenshotSrc"
          />
        </div>
        <div class="aspect-video rounded-lg bg-dark">
          <nuxt-img
            v-if="tertiaryScreenshotSrc"
            :key="tertiaryScreenshotSrc"
            :src="tertiaryScreenshotSrc"
          />
        </div>
      </div>
      <div class="m-1 pb-1">
        <div class="truncate text-white">
          <template v-if="overlay">{{ overlay.name }}</template>
          <template v-else>
            <div class="relative">
              &nbsp;
              <div
                class="absolute inset-0 my-px h-full w-full rounded-lg bg-dark"
              />
            </div>
          </template>
        </div>
        <div v-if="!hideDesigner" class="truncate text-neutral-400">
          <template v-if="overlay">{{ overlay.designer_name }}</template>
          <template v-else>
            <div class="relative">
              &nbsp;
              <div
                class="absolute inset-0 my-px h-full w-full rounded-lg bg-dark"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMouseInElement, useElementHover } from "@vueuse/core";
import { getOverlayPreviewImage, previewVideo } from "@/lib/util";

const el = ref(null);
const isHovered = useElementHover(el);

const props = withDefaults(
  defineProps<{
    overlay?: Overlay;
    animate?: boolean;
    hideAdditionalImages?: boolean;
    hideDesigner?: boolean;
  }>(),
  { animate: true },
);

const primaryScreenshotSrc = computed(() => {
  return props.overlay
    ? getOverlayPreviewImage(props.overlay, "live")
    : undefined;
});

const primaryVideoSrc = computed(() => {
  return props.overlay ? previewVideo(props.overlay) : undefined;
});

const secondaryScreenshotSrc = computed(() => {
  return props.overlay ? getOverlayPreviewImage(props.overlay, "start") : null;
});

const tertiaryScreenshotSrc = computed(() => {
  return props.overlay ? getOverlayPreviewImage(props.overlay, "brb") : null;
});
</script>
